// Step 1: Create a file src/contents/html-content.js with your content
// This file contains all your posts as JavaScript strings

export const notes_11_12_13_2025 = `
<div class="post-content">
  <p class="intro">
    It's been a bit of a ride—some days slower, some a little heavy. But in between cleaning things out, picking up small habits, and quiet resets, I've been finding my rhythm again.
  </p>
  <hr/>
  <ul>
    <li>
      <p>I have been using <a href="https://ticktick.com/?language=en_US">TickTick</a> regularly for the past three months—mostly to keep track of tasks. Over time, it became a habit that I decided to go for the premium plan at ₹250 a month (which is not bad and I may unsubscribe next month). The seamless sync across devices makes the whole experience smoother. Below is my monthly stats.</p>
      <img src="https://static.wixstatic.com/media/2402dd_495e81fc46d64b6e96ee12db6f0f0666~mv2.png/v1/fill/w_1480,h_980,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/2402dd_495e81fc46d64b6e96ee12db6f0f0666~mv2.png" alt="TikTik Stats"/>
    </li>
    
    <li>
      <p>Cleaning has always helped me clear my mind and find focus. Lately, weekends have become a loop of tidying and resetting—I also rearranged my wardrobe to have to only 10 paired outfits for work. Around the same time, I also joined a <a href="https://livingartlife.com/dot-mandala-art/">dot mandala</a> workshop with my friend Vidhya at HSR. The repetition felt so calming—much like cleaning. I enjoyed it so much that I purchased the tools <a href="https://www.amazon.in/Mandala-Painting-Designer-Acrylic-Dotting/dp/B0BTTS9V47/ref=asc_df_B0BTTS9V47/?tag=googleshopdes-21&linkCode=df0&hvadid=710045259325&hvpos=&hvnetw=g&hvrand=10463480567262150436&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9062008&hvtargid=pla-1966090086691&psc=1&mcid=4362b389145c3186a7cb141ba14e8deb&tag=googleshopdes-21&linkCode=df0&hvadid=710045259325&hvpos=&hvnetw=g&hvrand=10463480567262150436&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9062008&hvtargid=pla-1966090086691&psc=1&gad_source=1">online</a> and did it again the next week at home for a pot. All it really takes is a geometry box, some pencils, dotting tools, paint, and a bit more patience.</p>
      <img src="https://static.wixstatic.com/media/2402dd_a0796051354d4f9180e3ce669d62154d~mv2.jpeg/v1/fill/w_1480,h_1480,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/2402dd_a0796051354d4f9180e3ce669d62154d~mv2.jpeg" alt="Dot mandala artwork">
    </li>
    
    <li>
      <p>Work has been running good lately. I've noticed I solve bugs better in the mornings than at night, which works well since our standup's usually happen at 9 AM(on alternative days). I've also made a shift to work from Awfis, and if the weather holds up, I may try cycling to workspace as its nearby to my house (~2KM).</p>
    </li>
    
    <li>
      <p><a href="">Published</a> a blog on <a href="https://www.knowledgekeeper.ai/">KK</a> recently. It's something I've been dipping in and out of, but over the next two weeks, I'm planning to give it a bit more attention and hopefully get a basic version out. Let's see how it shapes up.</p>
    </li>
    
    <li>
      <p>As Krish Ashok said in a <a href="https://www.youtube.com/watch?v=sAwZHtkuza8">podcast</a>, being on a platform with 250 million people and their emotions is like holding a beer all the time—maybe okay once in a while, but harmful if you consume it every day. So I moved Social media apps(like X, Instagram etc, except Youtube) to another device. Now it's not part of my daily scroll at-least.</p>
    </li>
    
    <li>
      <p>On that note, I did the painful task of clearing out my inbox. It had gotten really crowded over time and has kind of become a yearly routine now. I enjoy getting weekly newsletters and blog updates, so I moved all my subscriptions under one label. That felt like a relief!</p>
    </li>
    
    <li>
      <p>We visited <a href="https://thepack.in/articles/thepack-reviews-neralu-organic-orchards-dog-park-in-bangalore">Neralu</a> one of the weekends with our friends in the apartment, and it was such a joy to see Tars jump into the lake on his own this time—no push needed. He looked much more comfortable in the water, and watching him paddle around happily was the best part of the trip.</p>
      <img src="https://static.wixstatic.com/media/2402dd_95197ea0f38c47f2bd6404ad04a8e524~mv2.jpg/v1/fill/w_1170,h_1341,al_c,q_85,enc_auto/2402dd_95197ea0f38c47f2bd6404ad04a8e524~mv2.jpg" alt="Tars at the lake">
    </li>
    <li>
      About 2 weeks back the full text RSS feed from wix has been <a href="https://forum.wixstudio.com/t/our-full-text-rss-feed-using-blog-feed-xml-stopped-working/69535">stopped working</a> (looks intended!). So this post of mine is just baked as a simple text reader.
    </li>
  </ul>
  </div>
</div>
`;